import {
  Box,
  Center,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react"
import { Link } from "gatsby"
import React from "react"
import { HeaderLink } from "../header"

interface Props {}

const Footer: React.FunctionComponent<Props> = props => (
  <Box
    width="100%"
    bgColor="black"
    as="footer"
    position="sticky"
    bottom={0}
    left={0}
    zIndex="-1"
  >
    <Center>
      <Box
        maxWidth={1440}
        width="100%"
        color="white"
        my={5}
        padding={4}
        textAlign={["center", "left"]}
      >
        <Stack flexDirection={["column", "row"]} spacing={0} color="darkgray">
          <Text>© Scythe Pty Ltd 2022</Text>
          <Spacer />
          <Text>Made with ❤️ in Sydney</Text>
        </Stack>
      </Box>
    </Center>
  </Box>
)

export default Footer
