import { Box, ChakraProvider } from "@chakra-ui/react"
import "../../css/main.css"
import { extendTheme } from "@chakra-ui/react"
import React from "react"
import Footer from "../footer"
import Header from "../header"

const theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        color: "black",
        scrollBehavior: "smooth",
      },
    },
  },
  fonts: {
    heading: "KH General",
    body: "General",
    color: "black",
  },
})

export const wrapPageElement = ({ element }) => {
  return (
    <ChakraProvider resetCSS theme={theme}>
      <Header />
      <Box
        minHeight="100vh"
        as="main"
        position="relative"
        z-index="1"
        backgroundColor="white"
      >
        {element}
      </Box>
      <Footer />
    </ChakraProvider>
  )
}
