import { Box, Center, HStack, Spacer, Text } from "@chakra-ui/react"
import { Link } from "gatsby"
import React from "react"

export const HeaderLink: React.FunctionComponent<{
  to: string
  text: string
  color: string
}> = ({ to, text, color }) => (
  <Link to={to}>
    <Box
      padding="0.6em 15px"
      borderRadius="50px / 40px"
      background={color}
      border={`2px solid ${color}`}
      _hover={{
        background: "transparent",
        color: color,
      }}
    >
      <Text fontSize={["1xl", "2xl"]}>{text}</Text>
    </Box>
  </Link>
)

const Header: React.FunctionComponent = () => (
  <Box
    padding="0px"
    paddingInline="0"
    position="fixed"
    width="100%"
    px={4}
    as="header"
    zIndex="2"
  >
    <Center>
      <Box maxWidth={1440} width="100%" py="3">
        <HStack spacing={["6", "12"]} as="nav">
          <HeaderLink to="/" text="Home" color="#ff6464" />
          <Spacer />
          <HeaderLink to="/#work" text="Work" color="#64ff69" />
          <HeaderLink to="/#studio" text="Studio" color="#64a9ff" />
        </HStack>
      </Box>
    </Center>
  </Box>
)

export default Header
